import { React, useContext, useEffect } from "react"
import { Navigate } from "react-router-dom"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./components/Home"
import { AuthContext, AuthProvider } from "./components/DAO/AuthContext"
import { TabProvider } from "./components/TabContext"
import PrivateCart from "./components/Cart/PrivateCart"
import ProtectedRoute from "./components/DAO/ProtectedRoute"

const App = () => {
  const { isAuthenticated } = useContext(AuthContext)

  return (
    <AuthProvider>
      <TabProvider>
        <Router>
          <Routes>
            {/* Protected Routes */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cart"
              element={
                <ProtectedRoute>
                  <PrivateCart />
                </ProtectedRoute>
              }
            />

            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </TabProvider>
    </AuthProvider>
  )
}

export default App
