import React, { useContext, useEffect, useState } from "react"
import "./Header.css"
import { AuthContext } from "../DAO/AuthContext"

const Header = ({ onHeaderLogoutClick, showLogout, showCart }) => {
  const { currentUser, isAuthenticated, isEmployee } = useContext(AuthContext)
  const [loggedin, setLoggedin] = useState(isAuthenticated)
  const [nameDisplayed, setNameDisplayed] = useState()
  const [nameIsHovered, setNameIsHovered] = useState(false)
  const [iconIsHovered, setIconIsHovered] = useState(false)
  const [textWidth, setTextWidth] = useState(0)

  //updates loggedin variable when AuthContext is updated
  useEffect(() => {
    setLoggedin(isAuthenticated)
  }, [isAuthenticated])

  const handleLogin = () => {
    window.location.href = "/accounts/login/"
  }

  const handleCartClick = () => {
    // IMPLEMENT
    window.location.href = "/cart"
  }

  //animate svg icons
  const handleLogoutHover = () => {
    setNameDisplayed("Log Out")
    setNameIsHovered(true)
  }

  const handleLogoutLeave = () => {
    if (!showLogout) {
      // If logout not clicked
      setNameDisplayed("Welcome, " + (currentUser?.first_name || ""))
      setNameIsHovered(false)
    }
  }

  useEffect(() => {
    handleLogoutLeave()
  }, [showLogout])

  const redirectToHome = () => {
    window.location.href = "/"
  }

  useEffect(() => {
    if (currentUser?.first_name !== undefined) {
      setNameDisplayed("Welcome, " + (currentUser?.first_name || ""))
      setNameIsHovered(false)
    }
  }, [currentUser])

  useEffect(() => {
    // calculate name width
    const font = '18px "HCo Ringside Narrow SSm"'
    const span = document.createElement("span")
    span.textContent = "Welcome, " + (currentUser?.first_name || "")
    span.style.font = font
    span.style.visibility = "hidden"
    span.style.whiteSpace = "nowrap"
    span.style.letterSpacing = "normal"
    document.body.appendChild(span)
    const width = span.offsetWidth
    document.body.removeChild(span)

    const padding = 5
    setTextWidth(width + padding)
  }, [nameDisplayed])

  return (
    <header className="byu-header">
      <div className="byu-official">
        <div className="byu-logo">
          {" "}
          {/* Image on the left of the header */}
          <svg
            class="byu-logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 80 23"
            onClick={redirectToHome}
          >
            <g fill="#FFF" fill-rule="nonzero">
              <path d="M9.126 8.654V4.788c0-.178-.014-.355-.043-.531.176.027.354.041.531.043h2.915c2.218 0 3.52.344 3.52 2.361 0 1.31-.809 2.478-3.43 2.478H9.614c-.174.002-.349.013-.522.035.022-.172.033-.346.034-.52m0 8.872V13.05c.001-.175-.011-.35-.036-.523.174.023.348.035.523.036h2.465c1.965 0 4.371 0 4.371 2.722 0 2.022-1.721 2.722-4.043 2.722H9.613a3.858 3.858 0 0 0-.516.044s.03-.268.03-.525M2.06 1.896v18.618c0 .604.036.819-.393 1.085-.428.265-.847.301-1.536.418H.117c-.068.018-.056.278.013.278h15.057c6.354 0 9.025-2.522 9.025-6.454 0-2.907-1.354-4.684-4.034-5.351a.043.043 0 0 1 0-.086c1.708-.533 3.278-1.769 3.278-4.795 0-4.104-2.773-5.488-8.822-5.488H.133c-.07 0-.084.26-.015.276h.013c.69.119 1.118.16 1.537.42.419.26.391.477.391 1.08M77.944 1.897c0-.603-.036-.815.392-1.081.43-.266.847-.302 1.537-.42h.013c.068-.016.056-.276-.013-.276h-11.11c-.068 0-.081.258-.012.276h.013c.7.088 1.027.16 1.444.42.417.26.391.478.391 1.081v11.189c0 2.552-1.461 4.511-4.362 4.511s-4.363-1.96-4.363-4.511V1.9c0-.603-.036-.818.393-1.084.429-.266.767-.325 1.444-.42h.013c.068-.018.055-.278-.014-.278H53.23c-.078 0-.062.262 0 .276h.013c.383.023.647.045.883.246.35.297.404.652.404 1.256v11c0 5.302 2.901 9.804 11.705 9.804 8.805 0 11.706-4.502 11.706-9.805l.003-10.998zM44.28 22.297c.07 0 .084-.26.015-.275h-.013c-.69-.119-1.118-.16-1.537-.42-.419-.26-.391-.48-.391-1.085V13.45l7.68-11.408c.422-.627.69-.953 1.036-1.243.093-.071.19-.137.289-.198.25-.147.538-.218.829-.207h.013c.069-.01.09-.276.011-.276H41.85c-.084 0-.067.272 0 .28h.011c.503 0 1.122 0 1.021.721-.056.702-3.042 5.02-4.107 6.659-.159.232-.268.495-.322.772a1.999 1.999 0 0 0-.33-.767C36.64 5.577 34.104 2 34.032 1.121c-.062-.748.518-.722 1.02-.722h.014c.072 0 .098-.275.013-.275H24.192c-.065 0-.058.25.007.275a.029.029 0 0 0 .013 0c.465.047.722.014 1.262.28.097.052.189.113.275.182.345.288.557.56.979 1.185l7.67 11.36v7.112c0 .604.037.82-.39 1.085-.428.266-.848.302-1.537.419h-.013c-.07.017-.057.277.013.277l11.81-.002z" />
            </g>
          </svg>
        </div>
        <div className="byu-separator"></div> {/* White separator bar */}
        <div className="byu-title">
          {" "}
          {/* This is the title text */}
          <h2 onClick={redirectToHome} style={{ cursor: "pointer" }}>
            ECE Shop
          </h2>
        </div>
      </div>
      <div class="menu">
        {loggedin ? (
          <>
            <div class="name-box" style={{ width: `${textWidth}px` }}>
              <h3
                class={`user-name ${nameIsHovered ? "hovered" : ""}`}
                onMouseEnter={handleLogoutHover}
                onMouseLeave={handleLogoutLeave}
                onClick={onHeaderLogoutClick}
              >
                {nameDisplayed}
              </h3>
            </div>
            {isEmployee && (
              <div
                class="svg-box"
                onMouseEnter={() => setIconIsHovered(true)}
                onMouseLeave={() => setIconIsHovered(false)}
                onClick={showCart ? handleCartClick : redirectToHome}
              >
                {showCart ? ( // Shopping bag icon
                  <svg
                    class="svg-icon"
                    viewBox="0 -0.5 25 25"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class={`stroke-path ${iconIsHovered ? "hovered" : ""}`}
                      d="M5.50035 9.3C5.487 8.31988 6.27024 7.51426 7.25035 7.5H17.7503C18.7305 7.51426 19.5137 8.31988 19.5004 9.3V17.4C19.5276 19.3605 17.9608 20.972 16.0004 21H9.00035C7.03989 20.972 5.4731 19.3605 5.50035 17.4V9.3Z M16.0004 10.2V6.6C16.0276 4.63953 14.4608 3.02797 12.5004 3C10.5399 3.02797 8.9731 4.63953 9.00035 6.6V10.2"
                    />
                  </svg>
                ) : (
                  // X icon
                  <svg
                    class="svg-icon"
                    viewBox="-2 -2 27 27"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class={`stroke-path ${iconIsHovered ? "hovered" : ""}`}
                      d="M6 6L18 18 M18 6L6 18"
                    />
                  </svg>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <h3
              class={`user-name ${nameIsHovered ? "hovered" : ""}`}
              onMouseEnter={() => setNameIsHovered(true)}
              onMouseLeave={() => setNameIsHovered(false)}
              onClick={handleLogin}
            >
              Sign In
            </h3>
            <div style={{ width: "20px" }}></div>
          </>
        )}
      </div>
    </header>
  )
}

export default Header
