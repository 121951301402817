import { React, Fragment, useContext, useEffect } from "react"
import { AuthContext } from "./DAO/AuthContext"
import { TabContext } from "./TabContext"
import TabBody from "./TabBody"
import Header from "./Header/Header"
import Logout from "./Logout/Logout"

function Home() {
  const { showLogout, updateShowLogout } = useContext(TabContext)
  const { isAuthenticated } = useContext(AuthContext)

  return (
    <Fragment>
      <Header
        onHeaderLogoutClick={() => updateShowLogout(true)}
        showLogout={showLogout}
        showCart={true}
      />
      <TabBody />
      {showLogout && <Logout onLogoutAction={() => updateShowLogout(false)} />}
    </Fragment>
  )
}

export default Home
