import React, { createContext, useState, useEffect } from "react"
import api from "../../api"
import { CART_URL } from "../../constants"
import { Spinner } from "reactstrap"

import axios from "axios"

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [currentUser, setCurrentUser] = useState()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isEmployee, setIsEmployee] = useState(false)
  const [loading, setLoading] = useState(true)

  // Check if the user is authenticated when the component mounts
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_GET_CURRENT_USER, { withCredentials: true })
      .then((response) => {
        setCurrentUser(response.data)
        setIsAuthenticated(response.data.is_authenticated)
        setIsLoggedIn(true)
        setIsEmployee(response.data.groups?.includes("Employees"))
      })
      .catch((error) => {
        console.error("Error fetching user:", error)
        setIsAuthenticated(false)
        setIsLoggedIn(false)
        setIsEmployee(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        isLoggedIn,
        isEmployee,
        loading,
      }}
    >
      {/* {children} */}
      {loading ? (
        <div className="page">
          <div className="loadingBox">
            <Spinner className="spinner" />
            <div className="message">Loading Page</div>
          </div>
        </div> // Render a loading indicator or spinner
      ) : (
        children // Render children only when loading is false
      )}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
