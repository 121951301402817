//Created by Logan Cropper 8/9/24

import React, { useState, useEffect, useContext, Fragment } from "react"
import { Col, Container, Row } from "reactstrap"
import { AuthContext } from "../DAO/AuthContext"
import axios from "axios"
//import api from "../../api"

import { PROJECT_PCB_URL } from "../../constants"
import Table from "../Table/Table"
import { createColumnHelper } from "@tanstack/react-table"
import NewProjectPCBModal from "./NewProjectPCBModal"

import StartJobModal from "./StartJobModal"
import InProgressModal from "./InProgressModal" // Import different modals
import CompleteModal from "./CompleteModal"
import EditStatusButton from "./EditStatusButton"
import MoveToCancelledModal from "./MoveToCancelledModal"

const ProjectPCBList = ({ statusFilter }) => {
  const columnHelper = createColumnHelper()
  const [projects, setProjects] = useState([])

  const { isAuthenticated, isEmployee } = useContext(AuthContext)

  const getModalComponent = (status, row) => {
    switch (status) {
      case "UN":
        return (
          <StartJobModal
            project={row?.original}
            type={"PCB Mill"}
            resetState={resetState}
            projectType={"pcb"}
          />
        )
      case "IP":
        return (
          <InProgressModal
            project={row?.original}
            resetState={resetState}
            projectType={"pcb"}
          />
        )
      case "CP":
        return (
          <CompleteModal
            project={row?.original}
            resetState={resetState}
            projectType={"pcb"}
          />
        )
      case "AR":
        return (
          <EditStatusButton
            project={row?.original}
            resetState={resetState}
            projectType={"pcb"}
          />
        )
      case "CN":
        return (
          <EditStatusButton
            project={row?.original}
            resetState={resetState}
            projectType={"pcb"}
          />
        )
      default:
        return null
    }
  }

  const resetState = () => {
    getProjects()
  }

  const columns = [
    columnHelper.accessor("button", {
      cell: ({ row }) =>
        isAuthenticated && isEmployee && getModalComponent(statusFilter, row),
      header: () => <span></span>,
    }),
    // columnHelper.accessor("purpose", {
    //   cell: (info) => info.getValue(),
    //   header: () => <span>Purpose</span>,
    // }),
    columnHelper.accessor("quantity", {
      cell: (info) => info.getValue(),
      header: () => <span>Quantity</span>,
    }),
    columnHelper.accessor("doubleSided", {
      cell: (info) => (info.getValue() ? "Double" : "Single"),
      header: () => <span>Board Sides</span>,
    }),
    columnHelper.accessor("customerName", {
      cell: (info) => info.getValue(),
      header: () => <span>Customer Name</span>,
    }),
    columnHelper.accessor("customerEmail", {
      cell: (info) => info.getValue(),
      header: () => <span>Customer Email</span>,
    }),
    columnHelper.accessor("date", {
      cell: (info) => info.getValue(),
      header: () => <span>Date</span>,
    }),
    columnHelper.accessor("buttons", {
      cell: ({ row }) => {
        return (
          isAuthenticated &&
          isEmployee && (
            <Row>
              <Col>
                <NewProjectPCBModal
                  create={false}
                  project={row.original}
                  resetState={resetState}
                />
              </Col>
              <Col>
                <EditStatusButton
                  project={row.original}
                  resetState={resetState}
                  projectType={"pcb"}
                />
              </Col>
              <Col>
                <MoveToCancelledModal
                  project={row.original}
                  resetState={resetState}
                  projectType={"pcb"}
                />
              </Col>
            </Row>
          )
        )
      },
      header: () => <span></span>,
    }),
  ]

  useEffect(() => {
    getProjects()
    resetState()
  }, [])

  const getProjects = async () => {
    try {
      const result = await axios.get(PROJECT_PCB_URL)
      setProjects(result.data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const filteredProjects = projects.filter((project) => {
    if (project.status === statusFilter) {
      return true
    } else {
      return false
    }
  })

  return (
    <Container className="main-container">
      <Table columns={columns} parentData={filteredProjects} paginate={true} />
    </Container>
  )
}

export default ProjectPCBList
