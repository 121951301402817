import React, { useState, useEffect, useContext } from "react"
import { Container } from "reactstrap"
import axios from "axios"

import { TabContext } from "../TabContext";
import api from "../../api"
import ItemCart from "./ItemCart"
import LoanCart from "./LoanCart"
import GroupFooter from "./GroupFooter"
import CartHeader from "./CartHeader"
import Logout from "../Logout/Logout"

const Cart = () => {
  const [totalPrice, setTotalPrice] = useState(0)
  const [data, setData] = useState([])
  const [loanData, setLoanData] = useState([])
  const [loadedCart, setLoadedCart] = useState(0) //this does not take loan cart into account yet
  const [loadedLoanCart, setLoadedLoanCart] = useState(0)
  const [group, setGroup] = useState(null)
  const { showLogout, updateShowLogout } = useContext(TabContext)

  const handleLogoutAction = () => {
    updateShowLogout(false)
  }

  const calculateTotalPrice = (items) => {
    return items
      .reduce((sum, row) => sum + row.price * row.quantity, 0)
      .toFixed(2)
  }

  const fetchCart = async () => {
    try {
      const response = await axios.get("/api/cart/", {
        withCredentials: true,
        // headers: {
        //   "X-CSRFToken": csrfToken,
        // },
      })
      const items = response.data.items

      setData(items)
      setLoadedCart(response.data.pk)
      setTotalPrice(calculateTotalPrice(items))
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchLoanCart = async () => {
    try {
      const response = await api.get(process.env.REACT_APP_LOANCART_URL)
      setLoanData(response.data.items)
      setLoadedLoanCart(response.data.pk)
    } catch (error) {
      console.log(error)
    }
  }

  const resetState = () => {
    fetchCart()
    fetchLoanCart()
  }

  useEffect(() => {
    resetState()
  }, [loadedCart])

  return (
    <div>
      <Container className="main-container" fluid="true">
        <CartHeader
          setLoadedCart={setLoadedCart}
          resetState={resetState}
          totalPrice={totalPrice}
          totalLoans={loanData.length}
          cartID={loadedCart}
          loanCartID={loadedLoanCart}
          group={group}
          setGroup={setGroup}
        />
        <Container className="main-container m-0" fluid="true">
          <ItemCart
            data={data}
            resetState={resetState}
            totalPrice={totalPrice}
          />
          <br />
          <br />
          <LoanCart loanData={loanData} resetState={resetState} />
        </Container>
        <GroupFooter setGroup={setGroup} group={group} />
      </Container>
      {showLogout && <Logout onLogoutAction={handleLogoutAction} />}
    </div>
  )
}

export default Cart
