// 7/26/24 - Modernized - ChatGPT
// Created by: Anthony Cardenas; Date: May 30, 2024
// Modernize to current React Version 7/30/24

import React, { useContext, useEffect, useState } from "react"
import { Container, Button } from "reactstrap"
import { EQUIPMENT_URL } from "../../constants"

import { AuthContext } from "../DAO/AuthContext"

import { createColumnHelper } from "@tanstack/react-table"

import Table from "../Table/Table"
import EquipmentModal from "./EquipmentModal"
import AddToLoanCartModal from "../Loans/AddToLoanCartModal"
import ConfirmRemovalModal from "../ConfirmRemovalModal"
import axios from "axios"
import api from "../../api"

import "./equipment.css"

//TODO: add in the table including equipment elements

const EquipmentTab = () => {
  const [equipment, setEquipment] = useState([])

  const { isAuthenticated, isEmployee } = useContext(AuthContext)

  const getEquipment = async () => {
    // Get items with the API
    try {
      const res = await axios.get(EQUIPMENT_URL)
      setEquipment(res.data)
    } catch (error) {
      console.error("Error fetching equipment data", error)
    }
  }

  useEffect(() => {
    // Reset the state on component mount
    getEquipment()
  }, [])

  const resetState = () => {
    // Reset the state
    getEquipment()
  }

  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("serialNumber", {
      header: () => <span>Serial Number</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("price", {
      header: "Price",
    }),
    columnHelper.accessor("lastSeenLocation", {
      header: () => <span>Last Seen Location</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("comments", {
      header: () => "Comments",
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("buttons", {
      header: () => <span></span>,
      cell: ({ row }) => {
        if (isAuthenticated && isEmployee) {
          return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {!row.original.isLoaned ? (
                <AddToLoanCartModal
                  item={row.original}
                  resetState={resetState}
                  cartType={"equipment"}
                  isEquipment={true}
                />
              ) : (
                <Button color="primary" outline disabled>
                  Loaned
                </Button>
              )}
              <EquipmentModal
                create={false}
                equipment={row.original}
                resetState={resetState}
              />

              <ConfirmRemovalModal
                pk={row.original.pk}
                name={row.original.name}
                resetState={resetState}
                apiUrl={EQUIPMENT_URL}
              />
            </div>
          )
        }
      },
    }),
  ]

  return (
    <Container className="main-container m-0" fluid="true">
      <div className="header">
        <div className="title-text">
          <h3>Equipment Database</h3>
        </div>
        <div className="add-item">
          {isAuthenticated && isEmployee && (
            <EquipmentModal create={true} resetState={resetState} />
          )}
        </div>
      </div>
      <Table columns={columns} parentData={equipment} searchable={true} />
    </Container>
  )
}

export default EquipmentTab
