import React, { useState, useContext } from "react"
import { TabList, TabPanel, Tabs, Tab } from "react-tabs"
import { Button, Col, Container, Row } from "reactstrap"
import "react-tabs/style/react-tabs.css"
import "./database.css"

import ItemsTab from "../Items/ItemsTab"
import EquipmentTab from "../Equipment/EquipmentTab"
import ReorderTab from "../Reorder/ReorderTab"
import { AuthContext } from "../DAO/AuthContext"

const DatabaseTab = ({ setMainSelectedTab }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const { isAuthenticated, isEmployee } = useContext(AuthContext)

  const handleTabChange = (index) => {
    setSelectedTab(index)
  }

  const handleCartButton = () => {
    setMainSelectedTab(4)
  }

  return (
    <Container className="main-container" fluid="true">
      <div className="title-text">
        <Row className="align-items-center justify-content-between">
          <h2>Shop Database</h2>
        </Row>
      </div>

      <Tabs selectedIndex={selectedTab} onSelect={handleTabChange}>
        <TabList>
          <Tab>Items</Tab>
          <Tab>Equipment</Tab>
          {isAuthenticated && isEmployee && <Tab>Reorder</Tab>}
        </TabList>
        <TabPanel>
          <ItemsTab />
        </TabPanel>
        <TabPanel>
          <EquipmentTab />
        </TabPanel>
        {isAuthenticated && isEmployee && (
          <TabPanel>
            <ReorderTab />
          </TabPanel>
        )}
      </Tabs>
    </Container>
  )
}

export default DatabaseTab
