import React, { useState, useContext } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import DatabaseTab from "./Database/DatabaseTab"
import ProjectsTab from "./Projects/ProjectsTab"
import LanderTab from "./Lander/LanderTab"
import ImprovedGroupsTab from "./Groups/ImprovedGroupsTab"
import ImprovedLoansTab from "./Loans/ImprovedLoansTab"
import ImprovedReceiptsTab from "./Receipts/ImprovedReceiptsTab"
import { AuthContext } from "./DAO/AuthContext"

const TabBody = ({ isTabSelected = true }) => {
  const [selectedTab, setSelectedTab] = useState(isTabSelected ? 0 : null)
  const { isAuthenticated, isEmployee } = useContext(AuthContext)

  const handleTabChange = (index) => {
    setSelectedTab(index)
  }

  return (
    <div>
      <Tabs selectedIndex={selectedTab} onSelect={handleTabChange}>
        <TabList>
          <Tab>Home</Tab>
          <Tab>Item Database</Tab>
          <Tab>Project Requests</Tab>
          {isAuthenticated && isEmployee && <Tab>Loans</Tab>}
          {isAuthenticated && isEmployee && <Tab>Receipts</Tab>}
          {isAuthenticated && isEmployee && <Tab>Groups</Tab>}
        </TabList>

        <TabPanel>
          <LanderTab />
        </TabPanel>

        <TabPanel>
          <DatabaseTab setMainSelectedTab={setSelectedTab} />
        </TabPanel>

        <TabPanel>
          <ProjectsTab setMainSelectedTab={setSelectedTab} />
        </TabPanel>

        {isAuthenticated && isEmployee && (
          <TabPanel>
            <ImprovedLoansTab />
          </TabPanel>
        )}

        {isAuthenticated && isEmployee && (
          <TabPanel>
            <ImprovedReceiptsTab />
          </TabPanel>
        )}

        {isAuthenticated && isEmployee && (
          <TabPanel>
            <ImprovedGroupsTab />
          </TabPanel>
        )}
      </Tabs>
    </div>
  )
}

export default TabBody
