import { Navigate } from "react-router-dom"
import { AuthContext } from "../DAO/AuthContext"
import { useContext, useState, useEffect } from "react"
import { TabContext } from "../TabContext"
import Header from "../Header/Header"
import Cart from "./Cart"
import "./Cart.css"

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Alert,
} from "reactstrap"

const PrivateRoute = () => {
  const { isAuthenticated, isEmployee } = useContext(AuthContext)
  const [isReady, setIsReady] = useState(false)
  const { showLogout, updateShowLogout } = useContext(TabContext)

  const handleHeaderLogoutClick = () => {
    updateShowLogout(true)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true)
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div>
      <Header
        showCart={false}
        onHeaderLogoutClick={handleHeaderLogoutClick}
        showLogout={showLogout}
      />
      {isReady ? (
        <>{isEmployee ? <Cart /> : <Navigate to="/" replace />}</>
      ) : (
        <div className="page">
          <div className="loadingBox">
            <Spinner className="spinner" />
            <div className="message">Loading Cart</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PrivateRoute
