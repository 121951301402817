import React, { useContext } from "react"
import { Navigate } from "react-router-dom"
import { Spinner } from "reactstrap"
import { AuthContext } from "../DAO/AuthContext"

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext)

  if (loading) {
    return (
      <div className="page">
        <div className="loadingBox">
          <Spinner className="spinner" />
          <div className="message">Loading Page</div>
        </div>
      </div>
    ) // Show a loading spinner while authentication state is being determined
  }

  if (!isAuthenticated) {
    // Redirect to SAML login
    window.location.href = "/accounts/login/"
    return null
  }

  return children
}

export default ProtectedRoute
