import React, { useContext, useState, useEffect, useRef } from "react"
import "./Logout.css"
import { AuthContext } from "../DAO/AuthContext"

const Logout = ({ onLogoutAction }) => {
  const { currentUser } = useContext(AuthContext)
  const [yesIsHovered, setYesIsHovered] = useState(true)
  const [noIsHovered, setNoIsHovered] = useState(false)

  const handleMouseEnterYes = () => {
    setYesIsHovered(true)
  }

  const handleMouseLeaveYes = () => {
    setYesIsHovered(false)
  }

  const handleMouseEnterNo = () => {
    setNoIsHovered(true)
    setYesIsHovered(false)
  }

  const handleMouseLeaveNo = () => {
    setNoIsHovered(false)
  }

  const popupRef = useRef(null)
  useEffect(() => {
    if (popupRef.current) {
      popupRef.current.focus()
    }
  }, [])

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && yesIsHovered) {
      handleLogout()
    }
    if (event.key === "Escape" || event.key === " ") {
      onLogoutAction()
    }
  }

  const handleLogout = async () => {
    onLogoutAction()

    // Navigate to the logout endpoint
    await fetch("/accounts/logout/", { method: "GET", credentials: "include" })
    window.location.href = "https://cas.byu.edu/cas/logout" // Redirect to homepage after logout
  }

  return (
    <div>
      <div class="background" onClick={onLogoutAction}></div>
      <div class="pop-up" onKeyDown={handleKeyDown} ref={popupRef} tabIndex={0}>
        <h1>Sign out?</h1>
        <h2>
          You are logged in as
          {" " + currentUser.first_name + " " + currentUser.last_name}
        </h2>
        <div class="options">
          <div
            class={`button ${noIsHovered ? "hovered" : ""}`}
            onMouseEnter={handleMouseEnterNo}
            onMouseLeave={handleMouseLeaveNo}
            onClick={onLogoutAction}
          >
            <h3>No</h3>
          </div>
          <div
            class={`button ${yesIsHovered ? "hovered" : ""}`}
            onMouseEnter={handleMouseEnterYes}
            onMouseLeave={handleMouseLeaveYes}
            onClick={handleLogout}
          >
            <h3>Yes</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Logout
