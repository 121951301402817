// import React, { createContext, useState, useContext } from 'react';

// const TabContext = createContext();

// export const TabProvider = ({ children }) => {
//   const [currentTab, setCurrentTab] = useState(0);

//   const updateCurrentTab = (newValue) => {
//     setCurrentTab(newValue);
//   };

//   console.log("TabProvider Rendered");

//   return (
//     <TabContext.Provider value={{ currentTab, updateCurrentTab }}>
//       {children}
//     </TabContext.Provider>
//   );
// };

// export const useTabContext = () => useContext(TabContext);

import React, { createContext, useState, useMemo, useEffect } from 'react';

const TabContext = createContext();

const TabProvider = ({ children }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [showLogout, setShowLogout] = useState(false);

  const updateCurrentTab = (newValue) => {
    setCurrentTab(newValue);
  };

  const updateShowLogout = (newValue) => {
    setShowLogout((prevShowLogout) => {
      console.log(newValue, prevShowLogout); // Logs the new value and the previous state
      return newValue; // Return the new value for the state update
    });
  };

  useEffect(() => {
    console.log('showLogout updated:', showLogout);
  }, [showLogout]);

  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(() => ({
    currentTab,
    updateCurrentTab,
    showLogout,
    updateShowLogout
  }), [currentTab, showLogout]);

  return (
    <TabContext.Provider value={value}>
      {children}
    </TabContext.Provider>
  );
};

export { TabContext, TabProvider }