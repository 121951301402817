//Updated to modern React 07/26/2024

import React, { useState, useEffect, useContext } from "react"
import { Button, Container } from "reactstrap"
import ItemModal from "./ItemModal"
import AddToCartModal from "./AddToCartModal"

import { AuthContext } from "../DAO/AuthContext"

import { ITEM_URL } from "../../constants"
import Table from "../Table/Table"

import { createColumnHelper } from "@tanstack/react-table"

import "./items.css"
import ConfirmRemovalModal from "../ConfirmRemovalModal"

import axios from "axios"

const ItemsTab = () => {
  const [items, setItems] = useState([])

  const { isAuthenticated, isEmployee } = useContext(AuthContext)

  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
      width: "15%",
    }),
    columnHelper.accessor("image", {
      cell: (info) => (
        <img
          src={info.getValue()}
          alt="Failed to Load"
          style={{ width: "75%", height: "75%" }}
        />
      ),
      header: () => <span></span>,
      width: "10%",
    }),
    columnHelper.accessor("description", {
      header: () => "Description",
      cell: (info) => info.renderValue(),
      width: "15%",
    }),
    columnHelper.accessor("location", {
      header: "Location",
      width: "8%",
    }),
    columnHelper.accessor("quantity", {
      header: () => <span>Stock</span>,
      width: "7%",
    }),
    columnHelper.accessor("price", {
      cell: (info) => {
        const priceValue = info.getValue()
        // Format the price to always show two decimal places
        return priceValue !== null && priceValue !== undefined
          ? `$${priceValue.toFixed(2)}`
          : "$0.00" // You can adjust this for how you want to display empty values
      },
      header: "Price",
      width: "7%",
    }),
    columnHelper.accessor("dataSheet", {
      header: "Datasheet",
      width: "7%",
      cell: (info) =>
        info.getValue() !== "" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Button onClick={() => window.open(info.getValue(), "_blank")}>
              Sheet
            </Button>
          </div>
        ) : null, // If the dataSheet is empty, render nothing
    }),
    columnHelper.accessor("buttons", {
      header: "",
      width: "38%",
      cell: ({ row }) => {
        if (isAuthenticated && isEmployee) {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <AddToCartModal
                item={row.original}
                resetState={resetState}
                isEquipment={false}
              />
              <ItemModal
                create={false}
                item={row.original}
                resetState={resetState}
              />
              <ConfirmRemovalModal
                pk={row.original.pk}
                name={row.original.name}
                resetState={resetState}
                apiUrl={ITEM_URL}
              />
            </div>
          )
        }
      },
    }),
  ]

  useEffect(() => {
    resetState()
  }, [])

  const getItems = async () => {
    try {
      const res = await axios.get(ITEM_URL)
      setItems(res.data)
    } catch (err) {
      console.log("Failed to retrieve items")
    }
  }

  const resetState = () => {
    // Reset the state
    getItems()
  }

  return (
    <Container className="main-container m-0" fluid="true">
      <div className="header">
        <div className="title-text">
          <h3>Item Database</h3>
        </div>
        <div className="add-item">
          {isAuthenticated && isEmployee && (
            <ItemModal create={true} resetState={resetState} />
          )}
        </div>
      </div>
      <Table
        parentData={items}
        searchable={true}
        columns={columns}
        paginate={true}
        fixedLayout={false}
      />
    </Container>
  )
}

export default ItemsTab
